import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import AciHwReleaseNoticeApiUrls from '../../../apiUrls/AciHwReleaseNoticeApiUrls'
import React, { useEffect, useState } from 'react'
import AciHwReleaseNoticeKeys from '../../../constants/locale/key/AciHwReleaseNotice'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'
import { FileForUserGetPartyId } from '../../../common/FileForUserCommon'
import Table from '../../../components/aciacehighway/Table'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE },
  CodeMaintenanceType
} = constants

function TablePage(props) {
  const { showNotification } = props

  const [lookups, setLookups] = useState(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation(Namespace.ACI_HW_RELEASE_NOTICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'ACIHWY_RNS_STATUS' }
      ])
    ]).then(([status]) => {
      setLookups({ status })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let entryNumber = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.ENTRY_NUMBER
    )
    let receivedDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.RECEIVED_DATE
    )
    let customsOfficeCode = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CUSTOMS_OFFICE_CODE
    )
    let warehouseCode = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.WAREHOUSE_CODE
    )
    let cbsaProcessingDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CBSA_PROCESSING_DATE
    )
    let releaseDate = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.RELEASE_DATE
    )
    let processingInd = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.PROCESSING_IND
    )
    let ccn = translate(
      Namespace.ACI_HW_RELEASE_NOTICE,
      AciHwReleaseNoticeKeys.CCN
    )

    return {
      receivedDate,
      entryNumber,
      customsOfficeCode,
      warehouseCode,
      cbsaProcessingDate,
      releaseDate,
      processingInd,
      ccn,
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    {
      field: "receivedDate",
      title: translatedTextsObject.receivedDate,
      render: (rowData) => 
          rowData.receivedDate && moment(rowData.receivedDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "entryNumber",
      title: translatedTextsObject.entryNumber,
    },
    {
      field: "customsOfficeCode",
      title: translatedTextsObject.customsOfficeCode,
    },
    {
      field: "warehouseCode",
      title: translatedTextsObject.warehouseCode,
    },
    {
      field: "cbsaProcessingDate",
      title: translatedTextsObject.cbsaProcessingDate,
      render: (rowData) => 
        rowData.cbsaProcessingDate && moment(rowData.cbsaProcessingDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "releaseDate",
      title: translatedTextsObject.releaseDate,
      render: (rowData) => 
        rowData.releaseDate && moment(rowData.releaseDate).format('DD/MM/YYYY HH:mm:ss')
    },
    {
      field: "processingInd",
      title: translatedTextsObject.processingInd,
      render: (rowData) => getLookupValue('status', rowData.processingInd)
    },
    {
      field: "ccn",
      title: translatedTextsObject.ccn,
    },
  ]

  const filters = [
    {
      label: translatedTextsObject.entryNumber,
      type: 'textfield',
      name: 'entryNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.ccn,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.customsOfficeCode,
      type: 'textfield',
      name: 'customsOfficeCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.warehouseCode,
      type: 'textfield',
      name: 'warehouseCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.processingInd,
      type: 'checkbox',
      name: 'processingInd',
      operatorType: IN,
      options: [
        {
          label: 'Goods released',
          value: 'GOODS_RELEASED',
          filterValue: {
            value: '4'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting arrival of goods',
          value: 'DECLARATION_ACCEPTED_AWAITING_ARRIVAL_OF_GOODS',
          filterValue: {
            value: '9'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting Customs Processing',
          value: 'DECLARATION_ACCEPTED_AWAITING_CUSTOMS_PROCESSING',
          filterValue: {
            value: '34'
          }
        },
        {
          label: 'Message Content Rejected with comment',
          value: 'MESSAGE_CONTENT_REJECTED_WITH_COMMENT',
          filterValue: {
            value: '2'
          }
        },
        {
          label: 'Message Content Accepted',
          value: 'MESSAGE_CONTENT_ACCEPTED',
          filterValue: {
            value: '1'
          }
        },
        {
          label: 'Goods required for examination - referred',
          value: 'GOODS_REQUIRED_FOR_EXAMINATION_REFERRED',
          filterValue: {
            value: '5'
          }
        },
        {
          label: 'Goods May Move, Detain at Destination (CFIA)',
          value: 'GOODS_MAY_MOVE_DETAIN_AT_DESTINATION_CFIA',
          filterValue: {
            value: '8'
          }
        },
        {
          label: 'Error message',
          value: 'ERROR_MESSAGE',
          filterValue: {
            value: '14'
          }
        },
        {
          label: 'Authorised to deliver CSA Shipment',
          value: 'AUTHORISED_TO_DELIVER_CSA_SHIPMENT',
          filterValue: {
            value: '23'
          }
        },
      ]
    },
    {
      label: translatedTextsObject.cbsaProcessingDate,
      type: 'daterange',
      name: 'cbsaProcessingDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.receivedDate,
      type: 'daterange',
      name: 'receivedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.releaseDate,
      type: 'daterange',
      name: 'releaseDate',
      operatorType: BETWEEN
    }
  ]

  return (
    <Table
      columns={columns}
      compact
      exportData={{ url: AciHwReleaseNoticeApiUrls.EXPORT }}
      fetch={{ url: AciHwReleaseNoticeApiUrls.GET }}
      fetchFilters={[          
        { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
      ]}
      filters={filters}
      showNotification={showNotification}
      sortConfig={{
        type: 'column',
        defaultField: 'receivedDate',
        defaultDirection: 'DESC'
      }}
    />
  )
}

export default TablePage
